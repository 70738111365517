<template>
  <div v-if="!mdata">Loading Please wait...
  </div>
  <div v-else>
    <validation-observer #default="{ invalid }">
      <b-form>
        <v-select
          v-if="$g.user.Rolle === 'admin'"
          v-model="mandantID"
          :options="mandanten"
          :reduce="val => val.value"
          @close="changeMandant"
        />
        <b-row>
          <b-col md="4">
            <validation-provider
              #default="{ errors }"
              name="Firma"
              rules="required"
            >
              <b-form-group
                :label="$t('firma')"
                label-for="Firma"
              >
                <b-form-input
                  id="Firma"
                  v-model="mdata.Firma"
                  :state="errors.length > 0 ? false:null"
                />
                <small
                  v-show="errors.length"
                  class="text-danger"
                >{{ $g.browserLocale === 'fr' ? 'Entreprise...' : 'Firma...' }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <div class="text-center mt-2">
              <b-img style="width: 100px; height: auto;"
                :src="logo"
              />
            </div>
          </b-col>
          <b-col md="4">
            <div class="mt-2">
              <b-form-file
                v-model="image"
                accept=".svg, .jpg, .png, .gif"
                size="sm"
                :state="Boolean(image)"
                placeholder="Logo auswählen oder hier ablegen..."
                drop-placeholder="Logo hier ablegen..."
              />
            </div>
          </b-col>

        </b-row>

        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Anrede"
              label-for="Anrede"
            >
              <v-select
                v-model="mdata.Anrede"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="anrede"
                :reduce="val => val.value"
                :clearable="false"
                input-id="Anrede"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Vorname"
              rules="required"
            >
              <b-form-group
                label="Vorname"
                label-for="Vorname"
              >
                <b-form-input
                  id="Vorname"
                  v-model="mdata.Vorname"
                  :state="errors.length > 0 ? false:null"
                />
                <small
                  v-show="errors.length"
                  class="text-danger"
                >{{ $g.browserLocale === 'fr' ? 'Prènom...' : 'Vorname...' }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Name"
              rules="required"
            >
              <b-form-group
                label="Name"
                label-for="name"
              >
                <b-form-input
                  id="Name"
                  v-model="mdata.Name"
                  :state="errors.length > 0 ? false:null"
                />
                <small
                  v-show="errors.length"
                  class="text-danger"
                >{{ $g.browserLocale === 'fr' ? 'Nom...' : 'Name...' }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Strasse"
              rules="required"
            >
              <b-form-group
                label="Strasse"
                label-for="Strassse"
              >
                <b-form-input
                  id="Strasse"
                  v-model="mdata.Strasse"
                  :state="errors.length > 0 ? false:null"
                />
                <small
                  v-show="errors.length"
                  class="text-danger"
                >{{ $g.browserLocale === 'fr' ? 'Nom...' : 'Name...' }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Plz"
              rules="required"
            >
              <b-form-group
                label="PLZ"
                label-for="Plz"
              >
                <b-form-input
                  id="Plz"
                  v-model="mdata.Plz"
                  :state="errors.length > 0 ? false:null"
                />
                <small
                  v-show="errors.length"
                  class="text-danger"
                >{{ $g.browserLocale === 'fr' ? 'NPA...' : 'Plz...' }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Ort"
              rules="required"
            >
              <b-form-group
                label="Ort"
                label-for="Ort"
              >
                <b-form-input
                  id="ort"
                  v-model="mdata.Ort"
                  :state="errors.length > 0 ? false:null"
                />
                <small
                  v-show="errors.length"
                  class="text-danger"
                >{{ $g.browserLocale === 'fr' ? 'Lieu...' : 'Ort...' }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="EMail"
              rules="required"
            >
              <b-form-group
                label="Empfänger EMail Abrechnung separis (Buchhaltung)"
                label-for="Email"
              >
                <b-form-input
                  id="email"
                  v-model="mdata.Email"
                  :state="errors.length > 0 ? false:null"
                />
                <small
                  v-show="errors.length"
                  class="text-danger"
                >{{ $g.browserLocale === 'fr' ? 'Couriel...' : 'EMail...' }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="IBAN"
              rules="validIBAN"
            >
              <b-form-group
                label="IBAN für Honorarrechnungen"
                label-for="IBAN"
              >
                <b-form-input
                  id="IBAN"
                  v-model="mdata.IBAN"
                  :state="errors.length > 0 ? false:null"
                />
                <small
                  v-show="errors.length"
                  class="text-danger"
                >{{ $g.browserLocale === 'fr' ? 'IBAN...' : 'IBAN...' }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            md="4"
          />

          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Kostenbez1"
              rules="required"
            >
              <b-form-group
                label="Kostenbezeichnung1"
                label-for="KostBez1"
              >
                <b-form-input
                  id="KostBez1"
                  v-model="mdata.KostenBez1"
                  :state="errors.length > 0 ? false:null"
                />
                <small
                  v-show="errors.length"
                  class="text-danger"
                >{{ $g.browserLocale === 'fr' ? 'Désignation coÛts manque' : 'Kostenbezeichnug fehlt!' }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Kostenbez2"
              rules="required"
            >
              <b-form-group
                label="Kostenbezeichnung2"
                label-for="KostBez2"
              >
                <b-form-input
                  id="KostBez2"
                  v-model="mdata.KostenBez2"
                  :state="errors.length > 0 ? false:null"
                />
                <small
                  v-show="errors.length"
                  class="text-danger"
                >{{ $g.browserLocale === 'fr' ? 'Désignation coÛts manque' : 'Kostenbezeichnug fehlt!' }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Kostenbez3"
              rules="required"
            >
              <b-form-group
                label="Kostenbezeichnung3"
                label-for="KostBez3"
              >
                <b-form-input
                  id="KostBez3"
                  v-model="mdata.KostenBez3"
                  :state="errors.length > 0 ? false:null"
                />
                <small
                  v-show="errors.length"
                  class="text-danger"
                >{{ $g.browserLocale === 'fr' ? 'Désignation coÛts manque' : 'Kostenbezeichnug fehlt!' }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            md="4"
            class="text-right"
          >
            <validation-provider
              #default="{ errors }"
              name="Kostensatz1"
              rules="required"
            >
              <b-form-group
                label="Kostensatz1"
                label-for="KostenSatz1"
              >
                <b-form-input
                  id="KostSatz1"
                  v-model="mdata.KostenSatz1"
                  class="text-right"
                  :state="errors.length > 0 ? false:null"
                />
                <small
                  v-show="errors.length"
                  class="text-danger"
                >{{ $g.browserLocale === 'fr' ? 'CoÛts manque' : 'Kostensatz fehlt!' }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="text-right"
          >
            <validation-provider
              #default="{ errors }"
              name="Kostensatz2"
              rules="required"
            >
              <b-form-group
                label="Kostensatz2"
                label-for="KostenSatz2"
              >
                <b-form-input
                  id="KostSatz2"
                  v-model="mdata.KostenSatz2"
                  class="text-right"
                  :state="errors.length > 0 ? false:null"
                />
                <small
                  v-show="errors.length"
                  class="text-danger"
                >{{ $g.browserLocale === 'fr' ? 'CoÛts manque' : 'Kostensatz fehlt!' }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="text-right"
          >
            <validation-provider
              #default="{ errors }"
              name="Kostensatz3"
              rules="required"
            >
              <b-form-group
                label="Kostensatz3"
                label-for="KostenSatz3"
              >
                <b-form-input
                  id="KostSatz3"
                  v-model="mdata.KostenSatz3"
                  class="text-right"
                  :state="errors.length > 0 ? false:null"
                />
                <small
                  v-show="errors.length"
                  class="text-danger"
                >{{ $g.browserLocale === 'fr' ? 'CoÛts manque' : 'Kostensatz fehlt!' }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            md="4"
            class="text-right"
          >
            <b-form-group
              label="Workflow Elternvereinbarung"
              label-for="Workflow Betreuung"
            >
              <v-select
                v-model="mdata.DosIDBetreuung"
                :options="workflow"
                :reduce="val => val.value"
                :clearable="false"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="text-right"
          >
            <b-form-group
              label="Workflow Trennung"
              label-for="Workflow Trennung"
            >
              <v-select
                v-model="mdata.DosIDTrennung"
                :options="workflow"
                :reduce="val => val.value"
                :clearable="false"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="text-right"
          >
            <b-form-group
              label="Workflow Scheidung"
              label-for="Workflow Scheidung"
            >
              <v-select
                v-model="mdata.DosIDScheidung"
                :options="workflow"
                :reduce="val => val.value"
                :clearable="false"
              />
            </b-form-group>
          </b-col>

        </b-row>
      </b-form>
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        :disabled="invalid"
        @click="save_changes"
      >
        Speichern
      </b-button>
      <b-button
        v-show="$g.user.Rolle === 'admin'"
        :disabled="mdata.Email === 'erhard@marro.ch'"
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="create_mandant"
      >
        Neuen Mandant anlegen basierend auf Eingaben!
      </b-button>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required } from '@validations'
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BFormFile, BImg,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import man from '@/service/mandant'
import db from '@/service/honorar'
import dbt from '@/service/dossier'

// import { Validator } from 'vee-validate'

const base64Encode = data => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(data)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    BImg,
  },
  data() {
    return {
      mandantID: null,
      image: null,
      mdata: null,
      logo: null,
      required,
    }
  },
  watch: {
    image(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.logo = value
            })
            .catch(() => {
              this.logo = null
            })
        } else {
          this.logo = null
        }
      }
    },
  },
  // computed: {
  //   logoUrl() {
  //     return 'data:image/jpeg;base64,' + btoa(new Uint8Array(this.mdata.Logo).reduce((data, byte) => data + String.fromCharCode(byte), '')) // eslint-disable-line
  //   },
  // },
  setup() {
    const anrede = [
      { label: 'Herr', value: 'herr' },
      { label: 'Frau', value: 'frau' },
    ]

    const workflow = []
    const mandanten = []

    return {
      anrede,
      workflow,
      mandanten,
    }
  },
  async created() {
    await this.fetch(this.$root.currMandantID)
  },
  async mounted() {
    extend('validIBAN', {
      async validate(value) {
        const r = await db.isValidIBAN(value)
        if (r !== null && r.data) {
          return true
        }
        return 'Ungültige IBAN!'
      },
    })

    const t = await dbt.getDossierWorkflowVorlage(this.$root.currMandantID)
    this.workflow = t.data !== null ? t.data : []

    // if (this.$g.user.Rolle === 'admin') {
    const m = await man.getMandant(0)
    this.mandanten = m.data
    // }
  },
  methods: {
    async fetch(manID) {
      const r = await man.getMandant(manID)
      if (r.data.length === 1) {
        this.mdata = r.data[0]
        this.mandantID = this.mdata.MandantID
      }
      const l = await man.getMandantLogo(manID)
      if (l.data !== null) {
        // this.image = l.data
        const fileURL = window.URL.createObjectURL(l.data)
        this.logo = fileURL
      }
    },
    async changeMandant() {
      await this.fetch(this.mandantID)
      // this.$g.user.MandantID = this.mandantID
      this.$root.currMandantID = this.mandantID
      await this.$root.$refs.FormBenutzer.refetch()
    },
    async save_changes() {
      this.mdata.Logo = this.image
      await man.putMandant(this.mdata)
      if (this.mdata.Logo !== null) await man.postMandantLogo(this.mdata.Logo, this.mdata.MandantID)
      this.$root.showToast('Mandant geändert', 'Mandantendaten erfolgreich geändert!')
    },
    async create_mandant() {
      await man.createMandant(this.mdata)
      this.$root.showToast('Neuer Mandant erstellt', 'Anmelden mit Login/[password], Login etc. anpassen und Passwort versenden!')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
